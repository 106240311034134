import React, { useEffect, useState } from 'react';
import { NewSSCOINAddr, NewSSCoinABI } from './contract/newSSCOIN';
import { useConnectWallet } from '@web3-onboard/react';
import { ethers } from 'ethers';
import { sellContractAbi, sellContractAddr } from './contract/SellContract';
import {
  MigrateContractABI,
  MigrateContractAddr,
} from './contract/migrateContract';
import bigInt from 'big-integer';
import { usdtAbi, usdtAddress } from './contract/USDT';
import { ToastContainer, toast } from 'react-toastify';
export default function BuySell() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const [handleBuySell, setHandleBuySell] = useState('buy');
  const [buttonStatus, setButtonStatus] = useState('approve');
  const [approveLoader, setApproveLoader] = useState(false);
  const [buyloader, setBuyloader] = useState(false);
  const [ssBalance, setSsBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);

  const [usdtValue, setUsdtValue] = useState(0);
  const [sscoinValue, setSScoinValue] = useState();
  const [usdtEstimateValue, setUsdtEstimateVal] = useState(0);

  const newSSCoinContract = () => {
    try {
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(
          wallet?.provider,
          'any'
        );
        const signer = provider.getSigner();
        const web = new ethers.Contract(NewSSCOINAddr, NewSSCoinABI, signer);
        console.log('🚀 ~ Migrate ~ web', web);
        return web;
      }
    } catch (error) {
      console.log('🚀 ~ etherCOntract ~ error', error);
    }
  };
  const sellContract = () => {
    try {
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(
          wallet?.provider,
          'any'
        );
        const signer = provider.getSigner();
        const web = new ethers.Contract(
          sellContractAddr,
          sellContractAbi,
          signer
        );
        console.log('🚀 ~ Migrate ~ web', web);
        return web;
      }
    } catch (error) {
      console.log('🚀 ~ etherCOntract ~ error', error);
    }
  };
  const usdtContract = () => {
    try {
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(
          wallet?.provider,
          'any'
        );
        const signer = provider.getSigner();
        const web = new ethers.Contract(usdtAddress, usdtAbi, signer);
        console.log('🚀 ~ Migrate ~ web', web);
        return web;
      }
    } catch (error) {
      console.log('🚀 ~ etherCOntract ~ error', error);
    }
  };
  useEffect(() => {
    if (wallet) {
      handleBalance();
    }
    return () => {};
  }, [wallet?.accounts[0]?.address]);

  const handleBalance = async () => {
    try {
      let _userSSCOINBalance = await sellContract().userSSCOINBalance();
      console.log(
        '🚀 ~ handleBalance ~ _userSSCOINBalance',
        _userSSCOINBalance.toString()
      );
      setSsBalance(Number(_userSSCOINBalance.toString() / 10 ** 18));
      let userUsdtBalance = await sellContract().userUsdtBalance();
      console.log(
        '🚀 ~ handleBalance ~ userUsdtBalance',
        userUsdtBalance.toString()
      );
      setUsdtBalance(Number(userUsdtBalance.toString() / 10 ** 18));
    } catch (error) {
      console.log('🚀 ~ handleBalance ~ error', error);
    }
  };

  const estimateValue = async (val) => {
    try {
      let amount = bigInt(val * 10 ** 18);
      let estimatValue = await sellContract().estimateUsdt(amount.toString());
      console.log('🚀 ~ estimateValue ~ estimatValue', estimatValue.toString());
      setUsdtValue(estimatValue.toString() / 10 ** 18);
    } catch (error) {
      console.log('🚀 ~ estimateValue ~ error', error);
    }
  };
  const handleEstimateUsdtValue=async(val)=>{
    let _price=await sellContract().exchangeRate()
    console.log("🚀 ~ handleEstimateUsdtValue ~ _price", _price.toString())
    let _val= ((val*100)/_price.toString())
    console.log("🚀 ~ handleEstimateUsdtValue ~ val", _val)
    setSScoinValue(Number(_val))
  }
  const handleApprove = async () => {
    if (!wallet?.accounts[0].address) {
      return toast.error('Please connect wallet first!');
    }
    try {
      setApproveLoader(true);
      let amount = bigInt(sscoinValue * 10 ** 18);

      let estimatValue = await sellContract().estimateUsdt(amount.toString());
      console.log('🚀 ~ handleApprove ~ estimatValue', estimatValue.toString());
      let approve = await usdtContract().approve(
        sellContractAddr,
        estimatValue.toString()
      );
      let waitfortx = await approve.wait();
      if (waitfortx) {
        setApproveLoader(false);
        setButtonStatus('buy');

        toast.success('Approve success!');
      }
    } catch (error) {
      setApproveLoader(false);
      console.log('🚀 ~ handleApprove ~ error', error);
    }
  };

  const handleBuy = async () => {
    try {
      setBuyloader(true);
      let amount = bigInt(sscoinValue * 10 ** 18);

      let buy = await sellContract().buySSCOIN(amount.toString());
      let waitfortx = await buy.wait();
      if (waitfortx) {
        setBuyloader(false);
        setButtonStatus('approve');
        toast.success('Buy Successfull');
        setUsdtValue(0);
        handleBalance();
      }
    } catch (error) {
      setBuyloader(false);
      let par = JSON.stringify(error);
      let _par = JSON.parse(par);
      console.log('🚀 ~ handleSell ~ _par', _par);
      if (_par?.reason) {
        toast.error(_par?.reason);
      } else {
        toast.error('Something went wrong!');
      }
      console.log('🚀 ~ handleBuy ~ error', error);
    }
  };

  const handleSSapproval = async () => {
    if (!wallet?.accounts[0].address) {
      return toast.error('Please connect wallet first!');
    }
    try {
      setApproveLoader(true);
      let amount = bigInt(sscoinValue * 10 ** 18);

      let approve = await newSSCoinContract().approve(
        sellContractAddr,
        amount.toString()
      );
      let waitfottx = await approve.wait();
      if (waitfottx) {
        toast.success('Approve success');
        setButtonStatus('sell');
        setApproveLoader(false);
      }
    } catch (error) {
      setApproveLoader(false);

      console.log('🚀 ~ handleSSapproval ~ error', error);
    }
  };
  const handleSellEstimateValue = async () => {
    try {
    } catch (error) {}
  };

  const handleSell = async () => {
    try {
      setBuyloader(true)
      let amount = bigInt(sscoinValue * 10 ** 18);
      console.log('🚀 ~ handleSell ~ amount', amount.toString());

      let sell = await sellContract().sellSSCOIN(amount.toString());
      let waitfortx = await sell.wait();
      if (waitfortx) {
        toast.success('Sell successfull!');
        setButtonStatus('approve');
      setBuyloader(false)
      handleBalance()

      }
    } catch (error) {
      setBuyloader(false)

      let par = JSON.stringify(error);
      let _par = JSON.parse(par);
      console.log('🚀 ~ handleSell ~ _par', _par);
      if (_par?.reason) {
        toast.error(_par?.reason);
      } else {
        toast.error('Something went wrong!');
      }
      console.log('🚀 ~ handleSell ~ error', error);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className='container-fluid pt-5 px-0'>
        <div className='row d-flex justify-content-center px-0'>
          {handleBuySell === 'buy' ? (
            //handle buy
            <div className='col-12 col-md-8 col-lg-5 '>
              <div className='bgColor shadow p-4'>
                <p className='text-center'>BUY SSCOIN</p>

                <div className='row py-3'>
                  <div className='col-4 pt-2'>
                    <p>
                      {' '}
                      <span>
                        <img
                          src='/tether.png'
                          alt='sscoin'
                          width={20}
                          height={20}
                        />
                      </span>{' '}
                      <span>USDT</span>{' '}
                    </p>
                  </div>
                  <div className='col-8'>
                    <input
                      type='text'
                      className='form-control '
                      name=''
                      id=''
                      placeholder='Enter value'
                      value={usdtValue}
                      onChange={(e)=>{
                        setUsdtValue(e.target.value)
                        handleEstimateUsdtValue(e.target.value)
                      }}
                      // disabled
                    />
                    <p className='text-end pt-1' style={{ fontSize: '12px' }}>
                      Balance : {usdtBalance.toFixed(1) ?? '0'}
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col text-center'>
                    <span
                      className='cursorPointer'
                      onClick={() => {
                        setHandleBuySell('sell');
                        setUsdtValue(0);
                        setSScoinValue(0);
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        fill='currentColor'
                        class='bi bi-arrow-down-up'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z'
                        />
                      </svg>
                    </span>
                  </div>{' '}
                </div>
                <div className='row pt-4 pb-2'>
                  <div className='col-4 pt-2'>
                    <p>
                      <span>
                        <img
                          src='/sscoin.png'
                          alt='sscoin'
                          width={40}
                          height={40}
                        />
                      </span>{' '}
                      SSCOIN
                    </p>
                  </div>
                  <div className='col-8'>
                    <input
                      type='text'
                      className='form-control'
                      name=''
                      id=''
                      placeholder='Enter value'
                      value={sscoinValue}
                      onChange={(e) => {
                        setSScoinValue(e.target.value);
                        estimateValue(e.target.value);
                      }}
                    />
                    <p className='text-end pt-1' style={{ fontSize: '12px' }}>
                      Balance : {ssBalance.toFixed(1) ?? '0'}
                    </p>
                  </div>
                </div>
                <div className='row'>
                  {buttonStatus === 'approve' ? (
                    <div className='col d-flex justify-content-center'>
                      {approveLoader ? (
                        <div class='spinner-border text-success' role='status'>
                          <span class='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        <button
                          className='btn btn-success'
                          onClick={handleApprove}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {buttonStatus === 'buy' ? (
                    <div className='col d-flex justify-content-center'>
                      {buyloader ? (
                        <div class='spinner-border text-success' role='status'>
                          <span class='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        <button className='btn btn-success' onClick={handleBuy}>
                          Buy SSCOIN
                        </button>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {handleBuySell === 'sell' ? (
            //handle sell
            <div className='col-12 col-md-8 col-lg-5  '>
              <div className=' shadow p-4 bgColor'>
                <p className='text-center'>SELL SSCOIN</p>

                <div className='row pt-3 pb-2'>
                  <div className='col-4 pt-2'>
                    <p>
                      <span>
                        <img
                          src='/sscoin.png'
                          alt='sscoin'
                          width={40}
                          height={40}
                        />
                      </span>{' '}
                      SSCOIN
                    </p>
                  </div>
                  <div className='col-8'>
                    <input
                      type='text'
                      className='form-control'
                      name=''
                      id=''
                      value={sscoinValue}
                      onChange={(e) => {
                        setSScoinValue(e.target.value);
                        estimateValue(e.target.value);
                      }}
                    />
                    <p className='text-end pt-1' style={{ fontSize: '12px' }}>
                      Balance : {ssBalance.toFixed(1) ?? '0'}
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col text-center'>
                    <span
                      className='cursorPointer'
                      onClick={() => {
                        setHandleBuySell('buy');
                        setUsdtValue(0);
                        setSScoinValue(0);
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='26'
                        height='26'
                        fill='currentColor'
                        class='bi bi-arrow-down-up'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z'
                        />
                      </svg>
                    </span>
                  </div>{' '}
                </div>
                <div className='row py-3'>
                  <div className='col-4 pt-2 pb-1'>
                    <p>
                      {' '}
                      <span>
                        <img
                          src='/tether.png'
                          alt='sscoin'
                          width={20}
                          height={20}
                        />
                      </span>{' '}
                      <span>USDT</span>{' '}
                    </p>
                  </div>
                  <div className='col-8'>
                    <input
                      type='text'
                      className='form-control '
                      name=''
                      id=''
                      value={usdtValue}
                      onChange={(e)=>{
                        setUsdtValue(e.target.value)
                        handleEstimateUsdtValue(e.target.value)
                      }}
                    />
                    <p className='text-end pt-1' style={{ fontSize: '12px' }}>
                      Balance : {usdtBalance.toFixed(1) ?? '0'}
                    </p>
                  </div>
                </div>
                <div className='row'>
                  {buttonStatus === 'approve' ? (
                    <div className='col d-flex justify-content-center'>
                      {approveLoader ? (
                        <div class='spinner-border text-success' role='status'>
                          <span class='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        <button
                          className='btn btn-success'
                          onClick={handleSSapproval}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {buttonStatus === 'sell' ? (
                    <div className='col d-flex justify-content-center'>
                      {buyloader ? (
                        <div class='spinner-border text-success' role='status'>
                          <span class='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        <button
                          className='btn btn-success'
                          onClick={handleSell}
                        >
                          Sell SSCOIN
                        </button>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { OLDSSABI, OLDSSADDRESS } from './contract/oldSSCOIN';
import { useConnectWallet } from '@web3-onboard/react';
import { ToastContainer, toast } from 'react-toastify';
import {
  MigrateContractABI,
  MigrateContractAddr,
} from './contract/migrateContract';
import bigInt from 'big-integer';
export default function Migrate() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const [ssCoinValue, setSScoinValue] = useState('');
  const [approveLoader, setApproveLoader] = useState(false);
  const [depositLoader, setDepositLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState('approve');
  const [oldssCoinBalance, setOldsscoinBalance] = useState(0);
  const [newssCoinBalance, setnewSscoinBalance] = useState(0);

  const oldSSContractInst = () => {
    try {
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(
          wallet?.provider,
          'any'
        );
        const signer = provider.getSigner();
        const web = new ethers.Contract(OLDSSADDRESS, OLDSSABI, signer);
        console.log('🚀 ~ Migrate ~ web', web);
        return web;
      }
    } catch (error) {
      console.log('🚀 ~ etherCOntract ~ error', error);
    }
  };
  const migrateContractInst = () => {
    try {
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(
          wallet?.provider,
          'any'
        );
        const signer = provider.getSigner();
        const web = new ethers.Contract(
          MigrateContractAddr,
          MigrateContractABI,
          signer
        );
        console.log('🚀 ~ Migrate ~ web', web);
        return web;
      }
    } catch (error) {
      console.log('🚀 ~ etherCOntract ~ error', error);
    }
  };

  const handleBalance = async () => {
    try {
      

    let oldBalance = await migrateContractInst().userOldSSCOINBalance();
    
    setOldsscoinBalance(Number (oldBalance.toString() / 10 ** 18));
    console.log('🚀 ~ handleBalance ~ oldBalance', oldBalance.toString());
    let newBalance = await migrateContractInst().userNewSSCOINBalance();
    setnewSscoinBalance(Number (newBalance.toString() / 10 ** 18));

    console.log('🚀 ~ handleBalance ~ newBalance', newBalance.toString());
  } catch (error) {
      console.log("🚀 ~ handleBalance ~ error", error)
      
  }
  };

  useEffect(() => {
    oldSSContractInst();
    if (wallet) {
      handleBalance();
    }
    return () => {};
  }, [wallet?.accounts[0]?.address]);

  const handleApprove = async () => {
    if (!wallet?.accounts[0]?.address) {
      return toast.error('Please connect wallet first!');
    }
    try {
      setApproveLoader(true);
      let amount = bigInt(ssCoinValue * 10 ** 18);
      let _approve = await oldSSContractInst().approve(
        MigrateContractAddr,
        amount.toString()
      );
      console.log('🚀 ~ handleApprove ~ _approve', _approve);
      let waitfortx = await _approve.wait();
      console.log('🚀 ~ handleApprove ~ waitfortx', waitfortx);
      if (waitfortx) {
        setApproveLoader(false);
        toast.success('Approved success');
        setButtonStatus('deposit');
      }
    } catch (error) {
      setApproveLoader(false);

      let par = JSON.stringify(error);
      let _par = JSON.parse(par);
      if (_par?.reason) {
        toast.error(_par?.reason);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };
  const handleDeposit = async () => {
    try {
      setDepositLoader(true);

      let amount = bigInt(ssCoinValue * 10 ** 18);

      let deposit = await migrateContractInst().migrate(amount.toString());
      let waitFortx = await deposit.wait();
      if (waitFortx) {
        setDepositLoader(false);
        toast.success('Migrate success');
        setButtonStatus('approve');
        handleBalance()
      }
    } catch (error) {
      let par = JSON.stringify(error);
      let _par = JSON.parse(par);
      if (_par?.reason) {
        toast.error(_par?.reason);
      } else {
        toast.error('Something went wrong!');
      }
      setDepositLoader(false);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className='container-fluid pt-3 pt-md-5 px-0'>
        <div className='row d-flex justify-content-center px-0'>
          <div className='col-12  col-md-8 col-lg-5 '>
            <div className='  shadow p-4 bgColor'>
              <div className='row py-3'>
                <div className='col-4 pt-2'>
                  <p>SSCOIN OLD</p>
                </div>
                <div className='col-8'>
                  <input
                    type='text'
                    value={ssCoinValue}
                    placeholder='Enter value'
                    onChange={(e) => setSScoinValue(e.target.value)}
                    className='form-control'
                    name=''
                    id=''
                  />
                  <p style={{fontSize:'12px'}} className='text-end'>Old Balance : {oldssCoinBalance?.toFixed(2)??'0'} SSCOIN</p>
                </div>
              </div>
              <div className='row py-3'>
                <div className='col-4 pt-2'>
                  <p>SSCOIN NEW</p>
                </div>
                <div className='col-8'>
                  <input
                    type='text'
                    placeholder='Enter value'
                    className='form-control'
                    name=''
                    id=''
                    value={ssCoinValue}
                  />
                  <p style={{fontSize:'12px'}} className='text-end'>New Balance : {newssCoinBalance?.toFixed(2)??'0'} SSCOIN </p>

                </div>
              </div>
              <div className='row'>
                {buttonStatus === 'approve' ? (
                  <div className='col d-flex justify-content-center'>
                    {approveLoader ? (
                      <div class='spinner-border text-success' role='status'>
                        <span class='visually-hidden'>Loading...</span>
                      </div>
                    ) : (
                      <button
                        className='btn btn-success'
                        onClick={handleApprove}
                      >
                        Approve
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}
                {buttonStatus === 'deposit' ? (
                  <div className='col d-flex justify-content-center'>
                    {depositLoader ? (
                      <div class='spinner-border text-success' role='status'>
                        <span class='visually-hidden'>Loading...</span>
                      </div>
                    ) : (
                      <button
                        className='btn btn-success'
                        onClick={handleDeposit}
                      >
                        Migrate
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import './App.css';
import { useConnectWallet } from '@web3-onboard/react';
import Navbar from './components/Navbar';

function App() {
  const [{ wallet }, connect] = useConnectWallet();
  console.log('🚀 ~ wallet', wallet);
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <Navbar />
          </div>
        </div>
      </div>
      {/* <button onClick={() => connect()}>Connect To access Personhood</button> */}
    </>
  );
}

export default App;

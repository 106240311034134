import React, { useState } from 'react';
import Migrate from './Migrate';
import BuySell from './BuySell';
import { useConnectWallet } from '@web3-onboard/react';
import { toast } from 'react-toastify';

export default function Navbar() {
  const [{ wallet }, connect] = useConnectWallet();
  console.log('🚀 ~ wallet', wallet);
  const [handleTab, setHandleTab] = useState(0);

  const shortAddress = (val) => {
    console.log('vaaa', val.substring(0, 4));
    return (
      val.substring(0, 6) + '...' + val.substring(val.length - 6, val.length)
    );
  };
  return (
    <>
      <div className='container-fluid pt-2'>
        <div className='row'>
          <div className='col-12 col-md-4 text-center text-md-start py-2 py-md-0'>
            <img
              src='/sscoin.png'
              width={30}
              height={30}
              alt='sscoin'
              className='img-fluid'
            />
          </div>
          <div className='col-12 col-md-4 d-flex justify-content-center gap-4'>
            <button
              className={` text-uppercase navMenu ${handleTab === 0 ? 'active' : ''
                }`}
              onClick={() => setHandleTab(0)}
            >
              Migrate
            </button>
            <button
              className={` text-uppercase navMenu ${handleTab === 1 ? 'active' : ''
                }`}
              onClick={() => setHandleTab(1)}
            >
              Buy/Sell
            </button>
          </div>
          <div className='col-12 col-md-4 d-flex justify-content-center py-2 py-md-0 justify-content-md-end'>
            {wallet?.accounts[0]?.address ? (
              <button className='walletButton text-uppercase'>
                {shortAddress(wallet?.accounts[0]?.address)}
              </button>
            ) : (
              <button
                className='walletButton text-uppercase'
                onClick={() => connect()}
              >
                connect Wallet
              </button>
            )}{' '}
          </div>
        </div>
      </div>
      <div className='container-fluid pt-4'>
        <div className='row '>
          {console.log('hanlde', handleTab)}
          <div className='col-12'>{handleTab === 0 ? <Migrate /> : ''}</div>
          <div className='col-12'>{handleTab === 1 ? <BuySell /> : ''}</div>
        </div>
      </div>
      <p className='text-center text-white cursorPointer pt-2'>
        SS COIN New Contract Address{' '}
        <span style={{}} className='border p-1'>
          {' '}
          0xd23d783ffC83FeE19F7FCf4901735cd14B3bcE85
        </span>
        <span
          className='px-1'
          onClick={() => {
            navigator.clipboard.writeText(
              '0xd23d783ffC83FeE19F7FCf4901735cd14B3bcE85'
            );
            toast.success('Copied');
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            class='bi bi-clipboard'
            viewBox='0 0 16 16'
          >
            <path d='M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z' />
            <path d='M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z' />
          </svg>
        </span>
      </p>
    </>
  );
}
